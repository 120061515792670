import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Table, Button, Spinner } from "react-bootstrap";
import {
    Container as ContainerMui,
    TextField,
    Button as ButtonMui,
    Typography,
    Grid,
    Box,
  } from "@mui/material";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Inventory from "./Inventory";
import Tarif from "./Tarif";
import Status from "./Status";

const Rooms = () => {
  const [inventory, setInventory] = useState({});
  const [tarifs, setTarifs] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [openCreateRoom, setOpenCreateRoom] = useState(false);
  const [openModal, setOpenModal] = useState({ type: null, id: null });

  const [roomDetails, setRoomDetails] = useState({
    title: "",
    description: "",
    maxPeople: 0,
    inventory: 0,
    price: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const datesPerPage = 10;

  const hotelParams = useParams();
  const hotelId = hotelParams.hotelId;

  const fetchInventory = async (month) => {
    setLoading(true);
    const startDate = new Date(month.getFullYear(), month.getMonth(), 2).toISOString().split("T")[0];
    const endDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    endDate.setHours(23, 59, 59, 999);
    const formattedEndDate = endDate.toISOString().split("T")[0];

    try {
      const response = await axios.get(`https://api.komorotel.com/api/rooms/inventory/${hotelId}`, {
        params: { startDate, endDate: formattedEndDate }, withCredentials:true
      });
      setInventory(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération de l'inventaire :", error);
    }
    setLoading(false);
  };

  const fetchTarifs = async (month) => {
    setLoading(true);
    const startDate = new Date(month.getFullYear(), month.getMonth(), 2).toISOString().split("T")[0];
    const endDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    endDate.setHours(23, 59, 59, 999);
    const formattedEndDate = endDate.toISOString().split("T")[0];

    try {
      const response = await axios.get(`https://api.komorotel.com/api/tarif/${hotelId}`, {
        params: { startDate, endDate: formattedEndDate }, withCredentials: true
      });

      setTarifs(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des tarifs :", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInventory(currentMonth);
    fetchTarifs(currentMonth);
  }, [currentMonth]);

  const handleMonthChange = (direction) => {
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + direction));
    setCurrentMonth(newMonth);
    setStartIndex(0);
  };

  const handleShowMoreDates = () => {
    const firstRoomType = Object.keys(inventory)[0];
    if (firstRoomType && startIndex + datesPerPage < inventory[firstRoomType].length) {
      setStartIndex(startIndex + datesPerPage);
    } else {
      setStartIndex(0);
    }
  };

    // Fonction pour gérer la soumission d'un formulaire de création de chambre
    const handleSubmit = async (event) => {

        try {
            const res = await axios(`https://api.komorotel.com/api/rooms/${hotelId}`, {
                method: 'post',
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    title: roomDetails.title,
                    desc: roomDetails.desc,
                    maxPeople: roomDetails.maxPeople,
                    price: roomDetails.price,
                    nombreDisponible: roomDetails.inventory,
                    capaciteInitiale: roomDetails.inventory

                }
            });
            alert("Nouvelle chambre crée avec succès !");
            setOpenCreateRoom(false)
            fetchInventory(currentMonth);
            fetchTarifs(currentMonth);
        } catch (err) {
            console.log(err); // Gestion des erreurs de la requête
        }
    };

  return (
    <Container>
      <div className="d-flex align-items-center mt-4 mb-4"><h1 className="">Disponibilités et Tarifs des Chambres</h1><p className="m-0  ms-2 text-primary p-1 bg-white shadow-sm h-75 rounded-pill" onClick={()=> setOpenCreateRoom(true)} style={{fontSize:"14px", cursor:"pointer"}}>+ Ajouter une chambre</p></div>
      <Row className="mb-3">
        <Col>
          <Button variant="" className="border shadow-sm" onClick={() => handleMonthChange(-1)}>
            Mois précédent
          </Button>
        </Col>
        <Col className="text-center">
          <h4>{currentMonth.toLocaleString("fr-FR", { month: "long", year: "numeric", timeZone: "UTC" }).replace(/^\w/, (c) => c.toUpperCase())}</h4>
        </Col>
        <Col className="text-end">
          <Button variant="" className="border shadow-sm" onClick={() => handleMonthChange(1)}>
            Mois suivant
          </Button>
        </Col>
      </Row>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <Table striped bordered hover responsive style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "200px", minWidth: "150px" }}>Type de Chambre</th>
                {Object.keys(inventory).length > 0 &&
                  inventory[Object.keys(inventory)[0]]
                    .slice(startIndex, startIndex + datesPerPage)
                    .map((entry, index) => (
                      <th key={index} style={{ width: "50px" }}>
                        {startIndex + index + 1}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(inventory).map((roomType) => (
                <React.Fragment key={roomType}>
                  <tr onClick={() => setOpenModal({type: "inventory", id: inventory[roomType][0].roomId})}>
                    <td><span className="fw-bold">{roomType}</span> - Disponibilités</td>
                    {inventory[roomType]
                      .slice(startIndex, startIndex + datesPerPage)
                      .map((entry, index) => (
                        <td key={index}>{entry.nombreDisponible}</td>
                      ))}
                  </tr>
                  <tr onClick={() => setOpenModal({type: "tarif", id: inventory[roomType][0].roomId})}>
                    <td><span className="fw-bold">{roomType}</span> - Tarifs</td>
                    {tarifs[roomType]
                      ?.slice(startIndex, startIndex + datesPerPage)
                      .map((entry, index) => (
                        <td key={index}>{entry.price || "N/A"} €</td>
                      ))}
                  </tr>
                  <tr onClick={() => setOpenModal({type: "status", id: inventory[roomType][0].roomId})}>
                    <td><span className="fw-bold">{roomType}</span> - Statuts</td>
                    {inventory[roomType]
                      ?.slice(startIndex, startIndex + datesPerPage)
                      .map((entry, index) => (
                        <td key={index}>{entry.statut.replace(/^\w/, (c) => c.toUpperCase())}</td>
                      ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <div className="text-center">
            <Button variant="primary" className="border" onClick={handleShowMoreDates}>
              <MdKeyboardDoubleArrowRight className="fs-4 text-white" />
            </Button>
          </div>
        </div>
      )}

      {openModal.type === "inventory" && (
        <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.418)" }}
        >
                <Inventory roomId={openModal.id} setOpenModal={setOpenModal} fetchInventory={fetchInventory} fetchTarifs={fetchTarifs} />
            
        </div>

      )}
      {openModal.type === "tarif" && (
        <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.418)" }}
        >
            <div className="p-4 rounded shadow-lg" style={{ backgroundColor: '#f8f9fa', maxWidth: '100%', minWidth: '300px' }}>
                <button className="position-fixed  btn-close" type="button" onClick={()=> {setOpenModal({type: null, id: null})} }  aria-label="Close"></button>
                <Tarif roomId={openModal.id} setOpenModal={setOpenModal} fetchInventory={fetchInventory} fetchTarifs={fetchTarifs}/>
            </div>
        </div>
      )}
      {openModal.type === "status" && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.418)" }}
        >
          <div className="p-4 rounded shadow-lg" style={{ backgroundColor: '#f8f9fa', maxWidth: '100%', minWidth: '300px' }}>
            <button className="position-fixed  btn-close" type="button" onClick={()=> {setOpenModal({type: null, id: null})} }  aria-label="Close"></button>
            <Status roomId={openModal.id} setOpenModal={setOpenModal} fetchInventory={fetchInventory} fetchTarifs={fetchTarifs}/>
          </div>
        </div>
      )}
      {openCreateRoom&&
                <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
                <ContainerMui
                maxWidth="sm"
                sx={{
                    bgcolor: "white",
                    p: 4,
                    borderRadius: 2,
                    boxShadow: 3,
                    overflow: "auto",
                }}
                >
                <Box display="flex" justifyContent="flex-end">
                    <ButtonMui
                    onClick={() => setOpenCreateRoom(false)}
                    color="error"
                    variant="contained"
                    size="small"
                    >
                    Fermer
                    </ButtonMui>
                </Box>
                <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="bold"
                    textAlign="center"
                    gutterBottom
                >
                    Ajouter une chambre
                </Typography>
                <Box component="form" sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Nom de la chambre"
                        name="title"
                        value={roomDetails.title}
                        onChange={handleChange}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        value={roomDetails.description}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        rows={2}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="Personnes max"
                        name="maxPeople"
                        type="number"
                        value={roomDetails.maxPeople}
                        onChange={handleChange}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        label="Inventaire"
                        name="inventory"
                        type="number"
                        value={roomDetails.inventory}
                        onChange={handleChange}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Prix (en €)"
                        name="price"
                        type="number"
                        value={roomDetails.price}
                        onChange={handleChange}
                        variant="outlined"
                        />
                    </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="space-between" mt={4} gap={2}>
                        <ButtonMui
                            fullWidth
                            variant="contained"
                            color="error"
                            onClick={() => setOpenCreateRoom(false)}
                            sx={{
                            fontWeight: "bold",
                            height: 48,
                            fontSize: "1rem",
                            }}
                        >
                            Annuler
                        </ButtonMui>
                        <ButtonMui
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit(roomDetails)}
                            sx={{
                            fontWeight: "bold",
                            height: 48,
                            fontSize: "1rem",
                            }}
                        >
                            Valider
                        </ButtonMui>
                    </Box>

                </Box>
                </ContainerMui>
                </div> }

    </Container>
  );
};

export default Rooms;
