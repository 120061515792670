import { Button, Container } from "react-bootstrap"
import useFetch from "../clients/features/get"
import { useContext, useEffect, useState } from "react"
import { SearchContest } from "../clients/utils/SearchContext"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { AuthContest } from "../clients/utils/AuthContext"
import { format } from "date-fns"

export default function Reserve({setOpen, hotelId, days, reservation, hotel}){

    const {data, loading, error}= useFetch(`https://api.komorotel.com/api/hotels/rooms/${hotelId}`)
    const [loadingReserve, setLoadingReserve]= useState(false)
    const [loadingCancel, setLoadingCancel]= useState(false)
    const [dataHotel, setDataHotel] = useState([])
    const [dataUser, setDataUser]= useState(null)
    const {dates}= useContext(SearchContest)
    const [totalPrice, setTotalPrice]= useState(null)
    const {user}= useContext(AuthContest)

    useEffect(() => {
        const userId= user?._id
        async function fetchMyAPI(){
            const res= await axios({
                method: 'get',
                url: `https://api.komorotel.com/api/user/${userId}`,
                withCredentials:true,
                
            })
            console.log(res.data)
            setDataUser(res.data)
        }
        if(user?.name){
        fetchMyAPI()}
      }, [loading])

    
    useEffect(() => {
        async function fetchMyAPI(){
            const res = await fetch(`https://api.komorotel.com/api/hotels/find/${hotelId}`).then((res) => res.json())
            setDataHotel(res)
        }
        fetchMyAPI()
      }, [loading])

      const handleCancel= async() => {
        setLoadingCancel(true)
        
        try{
            await axios({
                method: 'post',
                url: `https://api.komorotel.com/api/reservation/${dataUser?._id}/cancellation-request`,
                data: {
                    reservationId: dataUser?.reservationId._id
                },
                withCredentials:true,
            })
            setLoadingCancel(false)
            alert('Votre requête est envoyé, veuillez patienter un moment')
            navigate('/')
            
        }catch(err){
              setLoadingCancel(false)
              console.log(err)
              alert(err.response.data.message)
            }}
    
      
      useEffect(() => {
        if (data) {
          const fetchAllTarifs = async () => {
            const tarifsData = {};
            for (const item of data) {
              const checkInDate = format(new Date(dates[0].startDate), "yyyy-MM-dd");
              const checkOutDate = format(new Date(dates[0].endDate), "yyyy-MM-dd");
              const res = await axios({
                method: 'get',
                url: `https://api.komorotel.com/api/tarif/${item._id}/calculate-price?dateDebut=${checkInDate}&dateFin=${checkOutDate}`,
                withCredentials:true
              })
              tarifsData[item._id] = res.data.prixTotal;
              
            }
            
            setTotalPrice(tarifsData);
          };
          fetchAllTarifs();
        }
      }, [data, dates]);


    const getDateInRange = (start, end) => {
        const date= new Date(start.getTime())
        const list=[]

        while(date <= end){
            list.push(new Date(date).getTime())
            date.setDate(date.getDate()+1)
        }

        return list
    }
    const allDates= dates ? getDateInRange(dates[0].startDate, dates[0].endDate) : []
    const navigate = useNavigate()


    const isAvailable= () => {
        
    }
    const handleError = (roomNumber) => {
        if(!isAvailable(roomNumber)){
            alert ("Cette chambre n'est plus disponible")
            
        }
    }
    const handleReserve = async(price, roomTitle, roomId) => {        
        const checkInDate = format(new Date(dates[0].startDate), "yyyy-MM-dd");
        const checkOutDate = format(new Date(dates[0].endDate), "yyyy-MM-dd");
        setLoadingReserve(true)
                
                try{
                    
                           
                            if(dataHotel?.pms?.apiUrl){
                                const res= await axios({
                                    method: 'put',
                                    url: dataHotel?.pms?.apiUrl,
                                    headers: {
                                        'Authorization': `Bearer ${dataHotel?.pms?.apiKey}`,
                                        'Content-Type': 'application/json', 
                                    },
                                    withCredentials:true,
                                    
                                })
                                await axios({
                                    method: 'put',
                                    url: `https://api.komorotel.com/api/earn/increase`,
                                    withCredentials:true,
                                    data:{
                                        name: hotel,
                                        price: price,
                                        date: allDates,
                                        user: user.name+" "+user.surname,
                                        month: new Date().getMonth()
                                    }
                                })
                                
                            }else{
                                await axios({
                                    method: 'post',
                                    url: `https://api.komorotel.com/api/reservation`,
                                    withCredentials:true,
                                    data:{
                                        guest: {
                                            firstName: user.name,
                                            lastName: user.surname,
                                            email: user.email,
                                            id: user._id
                                          },
                                        roomId: roomId,
                                        hotelId: hotelId,
                                        checkInDate: checkInDate, 
                                        checkOutDate: checkOutDate, 
                                        totalPrice: totalPrice[roomId]
                                    }
                                })
                                await axios({
                                    method: 'put',
                                    url: `https://api.komorotel.com/api/earn/increase`,
                                    withCredentials:true,
                                    data:{
                                        name: hotel,
                                        price: price,
                                        date: allDates,
                                        user: user.name+" "+user.surname,
                                        month: new Date().getMonth()
                                    }
                                })
                            }
                        
                        
                        
                    
                    setOpen(false)
                    setLoadingReserve(false)
                    navigate("/")
                    alert("Votre réservation a été réalisée avec succès")

                }catch(err){
                    console.log(err)
                    setLoadingReserve(false)
                    alert(err.response.data.message)
                }
          
    }

    return(
        reservation ?
        <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
            <Container  className="bg-white d-none d-md-block w-50 h-50 p-2 rounded overflow-auto ">
                <button className="position-fixed start-75 m-1 btn-close" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                <div className="d-flex flex-column mx-3 mt-3">
                    
                        <div className="d-flex justify-content-between mx-5 mt-2 pb-7 border-bottom">
                            <div className="d-flex flex-column">
                                <p className="m-0 fw-bold">{reservation.roomId.title}</p>
                                <p className="m-0 fw-light">{reservation.roomId.desc}</p>
                                <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {reservation.roomId.maxPeople}</p>
                                <p className="m-0"> <span className="fw-bold">{reservation.roomId.price * days}€</span> pour {days} jours </p>
                                
                            </div>
                            
                            
                        </div>
                    
                </div>
                <Button disabled={loadingCancel} onClick={() =>reservation.canceled ? alert("Votre demande est en cours de traitement !"): handleCancel()} className="bg-danger text-white ms-7 fw-bold text-center w-75 mt-5 py-2">{ loadingCancel ? 'Chargement...' : 'Annuler la réservation !' }</Button>

            </Container>
            <Container  className="bg-white d-md-none w-75 h-50  rounded overflow-auto ">
                <button className="position-fixed start-75 m-1 btn-close" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                
                <div className="d-flex flex-column mx-3 mt-3">
                    
                        <div className="d-flex justify-content-between mt-2 pb-5 border-bottom">
                            <div className="d-flex flex-column">
                                <p className="m-0 fw-bold">{reservation.roomId.title}</p>
                                <p className="m-0 fw-light">{reservation.roomId.desc}</p>
                                <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {reservation.roomId.maxPeople}</p>
                                <p className="m-0"> <span className="fw-bold">{reservation.roomId.price * days}€</span> pour {days} jours  </p>
                            </div>
                            
                            
                        </div>
                   
                </div>
                <Button disabled={loadingCancel} onClick={() => reservation.canceled ? alert("Votre demande est en cours de traitement !"): handleCancel()} className="bg-danger ms-4 text-white fw-bold text-center mt-2 w-75 py-2">{ loadingCancel ? 'Chargement...' : 'Annuler la réservation !' }</Button>

            </Container>
        </div> :
        <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
            <Container  className="bg-white d-none d-md-block w-50 h-75 p-2 rounded overflow-auto ">
                <button className="position-fixed start-75 btn-close" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                <span className="fs-5 m-2 ms-4">Choisi ta chambre :</span>
                <div className="d-flex flex-column mx-3 mt-3 mb-2">
                    {data?.map((item) =>
                        totalPrice &&
                        <div>
                            <div className="mx-5 mt-2 mb-3 border-bottom">
                                <div className="d-flex justify-content-between mb-3 ">
                                    <div className="d-flex flex-column">
                                        <p className="m-0 fw-bold">{item.title}</p>
                                        <p className="m-0 fw-light">{item.desc}</p>
                                        <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {item.maxPeople}</p>
                                        <p className="m-0"> <span className="fw-bold">{totalPrice[item._id]}€</span> pour {days} jours du <span className="fw-bold">{format(dates[0].startDate, 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(dates[0].endDate, 'dd/MM/yyyy')}</span>  </p>
                                        
                                    </div>
                                    
                                </div>
                                <Button disabled={loadingReserve} onClick={() => handleReserve(item.price * days, item.title, item._id)} className="bg-primary text-white ms-7 fw-bold text-center w-75 mt-3 py-2">{ loadingReserve ? 'Chargement...' : 'Réservez maintenant !' }</Button>
                            </div>
                            
                        </div>
                    
                    )}
                </div>

            </Container>
            <Container  className="bg-white d-md-none w-75 h-75 p-2 rounded overflow-auto ">
                <button className="position-fixed start-75 btn-close m-1" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                <span className="fs-5 m-2">Choisi ta chambre :</span>
                <div className="d-flex flex-column mx-3 mt-3">
                    {data?.map((item) =>
                        totalPrice &&
                        <div>
                            <div className=" mt-2 mb-3 border-bottom">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex flex-column">
                                        <p className="m-0 fw-bold">{item.title}</p>
                                        <p className="m-0 fw-light">{item.desc}</p>
                                        <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {item.maxPeople}</p>
                                        <p className="m-0"> <span className="fw-bold">{totalPrice[item._id]}€</span> pour {days} jours du <span className="fw-bold">{format(dates[0].startDate, 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(dates[0].endDate, 'dd/MM/yyyy')}</span>  </p>
                                    </div>
                                    
                                </div>
                                <Button disabled={loadingReserve} onClick={() => handleReserve(item.price * days, item.title, item._id)} className="bg-primary text-white ms-4 fw-bold text-center w-75 mt-2 py-2">{ loadingReserve ? 'Chargement...' : 'Réservez maintenant !' }</Button>
                            </div>
                            
                        </div>
                    )}
                </div>

            </Container>
        </div>
    )
}