import React, { useState, useContext } from 'react';
import { Box, Typography, TextField, Button, Container, Paper } from '@mui/material';
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContest } from "../utils/AuthContext";

const LoginForm = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const { loading, error, dispatch } = useContext(AuthContest);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCredentials((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: 'LOGIN_START' });
    try {
      const res = await axios.post('https://api.komorotel.com/api/auth/login', {
        email: credentials.email,
        password: credentials.password,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      Cookies.set('access_token', res.data.access_token, {
        path: '/',
        secure: true,
        sameSite: 'None',
        domain: '.komorotel.com',
      });

      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
      navigate('/');
    } catch (err) {
      dispatch({ type: 'LOGIN_ERROR', payload: err.response?.data });
    }
  };

  return (
    <div>
      <Header disabled={true} />
      <Container maxWidth="sm" sx={{ mt: 7 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Se connecter ou créer un compte
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Entrez vos informations afin de pouvoir profiter de nos services
          </Typography>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{ width: '100%', mt: 3 }}
          >
            <TextField
              id="email"
              label="Adresse e-mail"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              onChange={handleChange}
            />
            <TextField
              id="password"
              label="Mot de passe"
              variant="outlined"
              type="password"
              fullWidth
              required
              margin="normal"
              onChange={handleChange}
            />
            {error && (
              <Typography
                color="error"
                align="center"
                variant="body2"
                sx={{ mt: 2 }}
              >
                {error.message}
              </Typography>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ mt: 3 }}
              disabled={loading}
            >
              {loading ? 'Chargement...' : 'Confirmer'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginForm;
