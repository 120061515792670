import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './clients/pages/App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './clients/sass/index.scss';
import HotelList from './clients/pages/HotelList';
import Hotel from './clients/pages/Hotel';
import Images from './clients/pages/Images';
import Login from './clients/pages/Login';
import Admin from './adminHotel/pages/Admin';
import CreateHotel from './adminHotel/pages/Hotel';
import SearchList from './clients/pages/SearchList';
import { SearchContextProvider } from './clients/utils/SearchContext';
import { AuthContest, AuthContextProvider } from './clients/utils/AuthContext';
import LogOut from './clients/pages/LogOut';
import Signup from './clients/pages/Signup';
import Hot_App from './components/Hot&App';
import HotelListType from './clients/pages/HotelListType';
import LoginAdmin from './adminHotel/pages/LoginAdmin';
import DevAdmin from './devAdmin/pages/Admin';
import ErrorAdmin from './adminHotel/pages/ErrorAdmin';
import EmailVer from './clients/pages/EmailVer';
import AccountType from './clients/pages/AccountType';
import LoginBus from './clients/pages/LoginBus';
import SignupBus from './clients/pages/SignupBus';
import LoginB from './clients/pages/LoginB_temp';
import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import AccountTypeLog from './clients/pages/AccountTypeLog';

const root = ReactDOM.createRoot(document.getElementById('root'));

function ProtectedRoute({ Component }) {
  const { user } = useContext(AuthContest);

  if (user?.username) {
    return <Component />;
  }
  return <ErrorAdmin />;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3A9D23',
    },
  },
});

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <SearchContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/hotels/:destination" element={<SearchList />} />
            <Route path="/island/:island" element={<HotelList />} />
            <Route path="/hotel/:hotelId" element={<Hotel />} />
            <Route path="/hotel/:hotelId/images" element={<Images />} />
            <Route path="/type/:type" element={<HotelListType />} />
            <Route path="/type/Appartement&Maison/warning" element={<Hot_App />} />
            <Route path="/login" element={<AccountTypeLog />} />
            <Route path="/login/user" element={<Login />} />
            <Route path="/login/user/:userId" element={<LoginB />} />
            <Route path="/business/login" element={<LoginBus />} />
            <Route path="/admin" element={<LoginAdmin />} />
            <Route path="/signup" element={<AccountType />} />
            <Route path="/signup/user" element={<Signup />} />
            <Route path="/business/signup" element={<SignupBus />} />
            <Route path="/signup/emailverification" element={<EmailVer />} />
            <Route path="/logout" element={<LogOut />} />
            <Route path="/admin/:userId" element={<ProtectedRoute Component={CreateHotel} />} />
            <Route path="/admin/:userId/:hotelId" element={<ProtectedRoute Component={Admin} />} />
            <Route path="/devAdmin/:id" element={<ProtectedRoute Component={DevAdmin} />} />
          </Routes>
        </ThemeProvider>
      </SearchContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);

reportWebVitals();
