import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { DateRange } from 'react-date-range';
import axios from 'axios';
import { format } from 'date-fns';

const Tarif = ({roomId, setOpenModal, fetchTarifs, fetchInventory}) => {
  const [dateRange, setDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), key: 'selection' },
  ]);
  const [tarif, setTarif] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeTarif = async () => {
    setLoading(true);
    const { startDate, endDate } = dateRange[0];
    const dateDebut = format(startDate, "yyyy-MM-dd");
    const dateFin = format(endDate, "yyyy-MM-dd");
    try {
      await axios({
        method:'post',
        url: `https://api.komorotel.com/api/tarif/${roomId}`,
        data : {
            dateDebut,
            dateFin,
            price: tarif,
        },
        withCredential:true
      });
      alert('Tarif mis à jour avec succès!');
      setOpenModal({type:null, id:null})
      fetchInventory(new Date())
      fetchTarifs(new Date())
    } catch (error) {
      console.error('Erreur lors de la mise à jour du tarif:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="h5" gutterBottom>
          Modifier les Tarifs
        </Typography>
        
        <TextField
          label="Tarif"
          value={tarif}
          onChange={(e) => setTarif(e.target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        
        <DateRange
          editableDateInputs={true}
          onChange={(item) => setDateRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={dateRange}
          variant="outlined"
        />
        
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeTarif}
          fullWidth
          disabled={loading}
        >
          {loading ? 'Chargement...' : 'Mettre à jour le tarif'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Tarif;
