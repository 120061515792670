import { Eventcalendar } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import useFetch from "../../clients/features/get_withcredentials";
import {useParams } from "react-router-dom";


import {useEffect, useState} from "react";

export default function Calendar(){
  const [list, updateList]= useState([])
  const hotelParams= useParams()
  const hotelId= hotelParams.hotelId

  const {data, loading}= useFetch(`https://api.komorotel.com/api/reservation/${hotelId}`)
  
  const generateRandomColor = () => {
      // Génère une couleur aléatoire en format hexadécimal
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    };
    
    useEffect(() => {
      const array = [];
      data?.forEach((reservation) => {
        const randomColor = generateRandomColor();
        array.push({
          start: reservation.checkInDate,
          end: reservation.checkOutDate,
          title: `${reservation.roomId.title} Occupé · ${reservation.guest.firstName} ${reservation.guest.lastName}`,
          color: randomColor,
          id: reservation._id,
          cssClass: 'd-flex align-items-center justify-content-center fw-bold py-3 m-1',
        });
      });
      updateList(array);
    }, [loading]);

  return(
    !list[0]  ?  
      <Eventcalendar
        width='1200px'
        height='1200px'
        theme="material"
        themeVariant="light"
      />  :
      <div className="border">
        <Eventcalendar 
          data={list.map((element)=> element)}
          width='1200px'
          height='1200px'
          theme="material"
          themeVariant="light"
        />
      </div>
  )
}
