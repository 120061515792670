import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper 
} from "@mui/material";
import Header from "../../components/Header";

export default function SignupBus() {
  const [credidentials, setCredidentials] = useState({
    email: "",
    password: "",
    name: "",
    phoneNumber: ""
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredidentials({ ...credidentials, [e.target.id]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    if (!credidentials.email || !/\S+@\S+\.\S+/.test(credidentials.email)) {
      errors.email = "Veuillez entrer un email valide.";
    }
    if (!credidentials.password || credidentials.password.length < 8) {
      errors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    }
    if (!credidentials.name) {
      errors.name = "Le nom de l'entreprise est requis.";
    }
    if (!credidentials.phoneNumber || !/^\d{10}$/.test(credidentials.phoneNumber)) {
      errors.phoneNumber = "Veuillez entrer un numéro de téléphone valide.";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    try {
      await axios.post("https://api.komorotel.com/api/business/register", {
        email: credidentials.email,
        name: credidentials.name,
        phoneNumber: credidentials.phoneNumber,
        password: credidentials.password
      });

      alert("Un e-mail vous a été envoyé. Veuillez le consulter pour finaliser l'inscription.");
      navigate("/business/login");
    } catch (err) {
      console.error(err);
      alert("Une erreur est survenue lors de l'enregistrement.");
    }
  };

  return (
    <div>
      <Header disabled={true} />

      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Créer un compte
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary" mb={3}>
            Veuillez entrer vos informations pour vous inscrire et accéder à nos services.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              id="email"
              label="Adresse e-mail"
              type="email"
              fullWidth
              margin="normal"
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              id="password"
              label="Mot de passe"
              type="password"
              fullWidth
              margin="normal"
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              id="name"
              label="Nom de l'entreprise"
              type="text"
              fullWidth
              margin="normal"
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              id="phoneNumber"
              label="Numéro de téléphone"
              type="text"
              fullWidth
              margin="normal"
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, py: 1.5 }}
            >
              Confirmer
            </Button>
          </Box>
          <Typography variant="body2" textAlign="center" color="text.secondary" mt={2}>
            Déjà un compte ?{" "}
            <Link to="/business/login" style={{ color: "#1976d2", textDecoration: "none" }}>
              Se connecter
            </Link>
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}
