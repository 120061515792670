import { Button, Grid, TextField, Typography, Container, Box, Link } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";

export default function Signup() {
  const [validated, setValidated] = useState(false);
  const [credidentials, setCredidentials] = useState({
    email: "",
    password: "",
    name: "",
    surname: ""
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredidentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const validateForm = () => {
    const errors = {};

    // Vérifier que l'email est valide
    if (!credidentials.email || !/\S+@\S+\.\S+/.test(credidentials.email)) {
      errors.email = "Veuillez entrer un email valide.";
    }

    // Vérifier que le mot de passe respecte les critères
    if (!credidentials.password || credidentials.password.length < 8) {
      errors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    }

    // Vérifier les champs de nom et prénom
    if (!credidentials.name) {
      errors.name = "Le prénom est requis.";
    }
    if (!credidentials.surname) {
      errors.surname = "Le nom est requis.";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formErrors = validateForm();
    setErrors(formErrors);

    // Si il y a des erreurs, ne pas soumettre le formulaire
    if (Object.keys(formErrors).length > 0) {
      setValidated(true);
      return;
    }

    try {
      const res = await axios.post('https://api.komorotel.com/api/auth/register', {
        email: credidentials.email,
        name: credidentials.name,
        surname: credidentials.surname,
        password: credidentials.password
      });

      alert("Un e-mail vous a été envoyé, veuillez l'ouvrir afin de vous connecter.");
      navigate(`/login/${res.data.user.id}`);
    } catch (err) {
      console.error(err);
      alert("Une erreur est survenue lors de l'enregistrement.");
    }
  };

  return (
    <div>
      <Header disabled={true} />

      <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="center" className="pt-4">
          <Grid item xs={12} sm={8} md={6}>
            <Box
              className="d-flex flex-column mt-4 border shadow-sm p-4 mx-3 rounded-3 h-100"
              sx={{ padding: 4 }}
            >
              <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
                Créer un compte
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Entrez vos informations afin de pouvoir profiter de nos services
              </Typography>
              <form noValidate validated={validated} onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Adresse e-mail"
                  type="email"
                  id="email"
                  value={credidentials.email}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  fullWidth
                  label="Mot de passe"
                  type="password"
                  id="password"
                  value={credidentials.password}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors.password}
                />
                <TextField
                  fullWidth
                  label="Prénom"
                  type="text"
                  id="name"
                  value={credidentials.name}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  fullWidth
                  label="Nom"
                  type="text"
                  id="surname"
                  value={credidentials.surname}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                  error={!!errors.surname}
                  helperText={errors.surname}
                />
                <Box className="d-flex flex-column" marginTop={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 3 }}
                  >
                    Confirmer
                  </Button>
                </Box>
              </form>

              <Typography variant="body2" textAlign="center" marginTop={2}>
                Déjà un compte ?{" "}
                <Link href="/login" style={{ color: "#1976d2", textDecoration: "none" }}>
                  Se connecter
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
