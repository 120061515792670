import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { DateRange } from 'react-date-range';
import axios from 'axios';
import { format } from 'date-fns';

const ChangeStatut = ({roomId, setOpenModal, fetchTarifs, fetchInventory}) => {
  const [dateRange, setDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), key: 'selection' },
  ]);
  const [statut, setStatut] = useState('opened');
  const [loading, setLoading] = useState(false);

  

  const handleChangeStatut = async () => {
    setLoading(true);
    const { startDate, endDate } = dateRange[0];
    const dateDebut = format(startDate, "yyyy-MM-dd");
    const dateFin = format(endDate, "yyyy-MM-dd");
    try {
      await axios({
        method:'put',
        url: `https://api.komorotel.com/api/rooms/${roomId}/status`,
        data : {
            dateDebut,
            dateFin,
            statut,
        },
        withCredentials:true
      });
      alert('Statut mis à jour avec succès!');
      setOpenModal({type:null, id:null})
      fetchInventory(new Date())
      fetchTarifs(new Date())
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid  container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="h5" gutterBottom>
          Modifier le Statut
        </Typography>
        
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Statut</InputLabel>
          <Select
            value={statut}
            onChange={(e) => setStatut(e.target.value)}
            label="Statut"
          >
            <MenuItem value="opened">Ouvert</MenuItem>
            <MenuItem value="closed">Fermé</MenuItem>
          </Select>
        </FormControl>
        
        <DateRange
          editableDateInputs={true}
          onChange={(item) => setDateRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={dateRange}
        />
        
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeStatut}
          fullWidth
          disabled={loading}
        >
          {loading ? 'Chargement...' : 'Mettre à jour le statut'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangeStatut;
