import { useNavigate, useParams } from "react-router-dom"
import useFetch from "../../clients/features/get_withcredentials"
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { format } from "date-fns"
import axios from "axios"
import { useState } from "react"
import { TbArrowsDownUp } from "react-icons/tb";

export default function Bills(){
   
    const [paramsSort, setParamsSort]= useState(true)
    const [paramsDeleted, setParamsDeleted]= useState(false)

    

    const {data, loading, error, refreshData}= useFetch(`https://api.komorotel.com/api/reservation`)

    const handleSort= (params) => {
        setParamsSort(params)
        refreshData()
    }
    const handleDelete= (params)=> {
        setParamsDeleted(params)
        refreshData()
    }



    return(
        loading  ? <div class="spinner-border bg-secondary align-middle text-primary" role="status"> </div>:
        <div className="w-100 h-100 bg-secondary">
            <h1 className="text-center pt-3">Bills List</h1>
            <div className="d-flex flex-column align-items-center justify-content-center  w-100 mt-5">
                <Container >
                    <Row>
                        <Col  sm={{ span: 4, offset: 4}} >
                            <Dropdown className=" text-center mb-5">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="bg-white shadow-sm rounded border">
                                <TbArrowsDownUp/> Filter by: (Filtrez par)
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item  onClick={()=> {handleSort(true); handleDelete(false)}}>Facture validées</Dropdown.Item>
                                    <Dropdown.Item  onClick={()=> {handleSort(false); handleDelete(false)}}>Factures non validées</Dropdown.Item>
                                    <Dropdown.Item  onClick={()=> {handleSort(false); handleDelete(true)}}>Factures effacées</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Container>
                
                {data?.map((bill)=>
                    <div className="d-flex flex-column bg-white justify-content-center w-50 border fs-6 text-center rounded m-2 p-3 shadow-sm">
                       <p className="fs-5 fw-bold text-center pb-3 border-bottom">{bill.room.roomType}</p>
                       <div className="d-flex fs-6  "><p className="fw-bold">Nom :</p> <p className="ms-4"> {bill.guest.name} </p></div>
                       <div className="d-flex fs-6 pb-3 border-bottom "><p className="fw-bold">Email :</p> <p className="ms-4"> {bill.guest.email} </p></div>

                       <div className="d-flex fs-6 mt-3  "><p className="fw-bold">Date :</p> <p className="ms-4">Du <span className="fw-bold">{format(bill.room.checkInDate, 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(bill.room.checkOutDate, 'dd/MM/yyyy')}</span> </p></div>
                       <div className="d-flex fs-6  "><p className="fw-bold">Montant de la facture :</p> <p className="ms-4"> <span className="fw-bold">{bill.price} €</span> </p></div>

                       





                    </div>
                )}
                
            </div>
        </div>
        )
}