import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Box, Button, Container, TextField, Typography, Alert, Paper } from "@mui/material";
import { AuthContest } from "../../clients/utils/AuthContext";
import Header from "../../components/Header";

export default function LoginAdmin() {
  const [credidentials, setCredidentials] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContest);

  const handleChange = (e) => {
    setCredidentials({ ...credidentials, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      const res = await axios.post(
        "https://api.komorotel.com/api/authAdmin/login",
        {
          username: credidentials.username,
          password: credidentials.password
        },
        { withCredentials: true }
      );

      let expires = new Date();
      expires.setTime(expires.getTime() + 3600 * 1000); // 1 heure

      Cookies.set("access_token", res.data.access_token, {
        path: "/",
        secure: true,
        sameSite: "None",
        domain: ".komorotel.com",
        expires: expires
      });

      dispatch({ type: "LOGIN_SUCCES", payload: res.data });

      if (res.data.hotelId !== null) {
        navigate(`/admin/${res.data._id}/${res.data.hotelId}`);
      } else {
        navigate(`/admin/${res.data._id}`);
      }

      if (res.data.isBoss) {
        navigate(`/devAdmin/${res.data._id}`);
      }
    } catch (err) {
      setError(err.response.data);
      dispatch({ type: "LOGIN_ERROR", payload: err.response.data });
    }
  };

  return (
    <Box className="w-100 h-100">
      <Header />
      <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 8 }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          <span style={{ background: "#3A9D23", color: "#fff", padding: "0.5rem 1rem", borderRadius: "8px" }}>
            Komorotel
          </span>
        </Typography>

        <Paper elevation={3} sx={{ p: 4, width: "100%", mt: 4 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Connexion Administrateur
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <TextField
              id="username"
              label="Nom d'utilisateur"
              fullWidth
              margin="normal"
              onChange={handleChange}
              required
            />
            <TextField
              id="password"
              label="Mot de passe"
              type="password"
              fullWidth
              margin="normal"
              onChange={handleChange}
              required
            />

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error.message || "Une erreur est survenue."}
              </Alert>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, py: 1.5 }}
            >
              Confirmer
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
