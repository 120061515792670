import { Button, Grid, TextField, Typography, Container, Box } from "@mui/material";
import { useContext, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AuthContest } from "../utils/AuthContext";
import axios from "axios";
import Header from "../../components/Header";

export default function LoginBus() {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [credidentials, setCredidentials] = useState({
    email: "",
    password: "",
  });

  const { loading, error, dispatch } = useContext(AuthContest);

  const handleChange = (e) => {
    setCredidentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios({
        method: "post",
        url: "https://api.komorotel.com/api/business/login",
        headers: { "Content-Type": "application/json" },
        data: {
          email: credidentials.email,
          password: credidentials.password,
        },
      });

      Cookies.set("access_token", res.data.access_token, {
        path: "/",
      });

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/");
    } catch (err) {
      dispatch({ type: "LOGIN_ERROR", payload: err.response.data });
      console.log(err);
    }
  };

  return (
    <div>
      <Header disabled={true} />
      <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="center" className="pt-7">
          <Grid item xs={12} sm={8} md={6}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
                <span className="bg-primary p-2 text-white rounded-3">Entreprise</span>
              </Typography>
            <Box
              className="d-flex flex-column mt-4 border shadow-sm p-4 mx-3 rounded-3 h-100"
              sx={{ padding: 4 }}
            >
              
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Se connecter ou créer un compte
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Entrez vos informations afin de pouvoir profiter de nos services
              </Typography>
              <form noValidate validated={validated} onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Adresse e-mail"
                  type="email"
                  id="email"
                  value={credidentials.email}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Mot de passe"
                  type="password"
                  id="password"
                  value={credidentials.password}
                  onChange={handleChange}
                  required
                  margin="normal"
                  variant="outlined"
                />
                <Box className="d-flex flex-column" marginTop={3}>
                  {error && (
                    <Typography color="error" align="center">
                      {error.message}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 3 }}
                  >
                    Confirmer
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
