import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import useFetch from "../../clients/features/get_withcredentials";

const Bills = () => {
  const [loading, setLoading] = useState(true);
  const [reservations, setReservations] = useState([]);
  const [error, setError] = useState(null);

  const hotelParams = useParams();
  const hotelId = hotelParams.hotelId;

  const {data, refreshData }=useFetch(`https://api.komorotel.com/api/reservation/${hotelId}`)


  useEffect(() => {
    const fetchReservations = async () => {
      try {
        setLoading(true);
        const { data } = await axios({
          method: "get",
          url: `https://api.komorotel.com/api/reservation/${hotelId}/pending-cancellations`,
          withCredentials: true,
        });
        setReservations(data);
      } catch (err) {
        setError(err.message || "Une erreur est survenue");
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, [hotelId]);

  const calculateNights = (checkInDate, checkOutDate) => {
    const startDate = new Date(checkInDate);
    const endDate = new Date(checkOutDate);
    return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  const handleCancelReservation = async (reservationId, userId) => {
    try {
      setLoading(true);
      await axios({
        method: 'post',
        url: `https://api.komorotel.com/api/reservation/${userId}/cancellation-request`,
        data: {
            reservationId
        },
        withCredentials:true,
      });
      alert("La demande d'annulation a bien été effectué !")
      // Mise à jour des réservations après annulation
      setReservations((prevReservations) =>
        prevReservations.filter((res) => res._id !== reservationId)
      );
    } catch (err) {
      console.error("Erreur lors de l'annulation :", err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1 className="mt-4 mb-4">Liste des réservations</h1>
        </Col>
      </Row>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : error ? (
        <div className="text-danger text-center">{error}</div>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <Table striped bordered hover responsive style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Date</th>
                <th>Nombre de nuitées</th>
                <th>Type #</th>
                <th>Prix Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((reservation) => (
                <tr key={reservation._id}>
                  <td>{reservation.guest?.lastName || "N/A"}</td>
                  <td>{reservation.guest?.firstName || "N/A"}</td>
                  <td>{new Date(reservation.createdAt).toLocaleDateString("fr-FR")}</td>
                  <td>
                    {calculateNights(
                      reservation.checkInDate,
                      reservation.checkOutDate
                    )}
                  </td>
                  <td>{reservation.roomId?.title || "N/A"}</td>
                  <td>{reservation.totalPrice || "N/A"} €</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleCancelReservation(reservation._id, reservation.guest.id)}
                    >
                      Annuler (Le client n'est pas venu)
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default Bills;
