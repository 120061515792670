import React from "react";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

export default function AccountType() {
  return (
    <Box>
      <Header disabled={true} />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          mt: -15,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Créer un compte
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            1- Choisissez quel type de compte vous voulez créer
          </Typography>
          <Button
            component={Link}
            to="/business/signup"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              my: 2,
              py: 1.5,
              fontSize: "1rem",
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            Créer un compte Entreprise
          </Button>
          <Button
            component={Link}
            to="/signup/user"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              py: 1.5,
              fontSize: "1rem",
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            Créer un compte Personnel
          </Button>
        </Paper>
      </Container>
    </Box>
  );
}
