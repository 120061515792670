import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // CSS principal
import "react-date-range/dist/theme/default.css"; // Thème par défaut
import { format } from "date-fns";
import axios from "axios";

const ChangeInventory = ({roomId, setOpenModal, fetchTarifs, fetchInventory}) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [inventory, setInventory] = useState(0);

  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleSubmit = async() => {
    const { startDate, endDate } = dateRange[0];
    const dateDebut = format(startDate, "yyyy-MM-dd");
    const dateFin = format(endDate, "yyyy-MM-dd");
    const payload = {
      dateDebut,
      dateFin,
      inventory: parseInt(inventory, 10),
    };

    // Appel API pour envoyer les données
    await axios(`https://api.komorotel.com/api/rooms/inventory/${roomId}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload,
      withCredentials:true
    })
      .then((response) => {
        if (!response.ok) throw new Error("Erreur lors de la mise à jour");
        return response.json();
      })
      .then((data) => {
        alert("Disponibilité mise à jour avec succès !");
        setOpenModal({type:null, id:null})
        fetchInventory(new Date())
        fetchTarifs(new Date())
      })
      .catch((error) => {
        console.error("Erreur :", error);
        alert("Une erreur est survenue.");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        padding: 2,
        border: "1px solid #ddd",
        borderRadius: 4,
        maxWidth: 500,
        margin: "0 auto",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
        <button className="position-fixed start-0  btn-close" type="button" onClick={()=> {setOpenModal({type: null, id: null})} }  aria-label="Close"></button>

      <Typography variant="h5">Modifier la disponibilité</Typography>
      <DateRange
        editableDateInputs={true}
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
      />
      <TextField
        label="Disponibilité"
        type="number"
        value={inventory}
        onChange={(e) => setInventory(e.target.value)}
        sx={{ width: "100%" }}
        inputProps={{ min: 0 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 2 }}
      >
        Mettre à jour
      </Button>
      <Button
        variant="contained"
        color="warning"
        onClick={()=> setOpenModal({type: null, id:null})}
        sx={{ mt: 2 }}
      >
        Annuler
      </Button>

      

    </Box>
  );
};

export default ChangeInventory;
